<style>
.data-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.data-grid table {
  flex: 1;
}
#CustomerService,
#Bureau {
  padding: 10px;
  border-radius: 7px;
}
#Bureau {
  margin-left: 10px;
  flex: 1;
  /* background: rgb(228, 239, 255); */
}

#CustomerService {
  margin-bottom: 10px;
  /* background: rgb(228, 239, 255); */
  flex: 1;
}

.contactDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: self-start;
}
</style>
<template>
  <div>
    <div v-if="contactGroupDic">
      <div v-if="contactGroupDic" class="contactDetails">
        <div id="CustomerService" class="box">
          <h4>Customer Service</h4>
          <div class="data-grid" v-if="contactGroupDic.Product">
            <contact-table
              v-for="(c, index) in contactGroupDic.Product.Contacts"
              :contact="c"
              :index="(contactGroupDic.Product.Contacts.length > 1 ? index + 1 : undefined)"
              :key="c.Id"
            ></contact-table>
          </div>
          <p v-else>No Customer Service Contact Details</p>
        </div>

        <div id="Bureau" class="box">
          <h4>Fulfilment Bureau</h4>
          <div class="data-grid" v-if="contactGroupDic.Destination">
            <contact-table
              v-for="(c, index) in contactGroupDic.Destination.Contacts"
              :contact="c"
              :index="(contactGroupDic.Destination.Contacts.length > 1 ? index + 1 : undefined)"
              :key="c.Id"
            ></contact-table>
          </div>
          <p v-else>No Customer Service Contact Details</p>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading">Loading...</div>

    <!-- <span
              v-if="c.Email"
              style="cursor:pointer"
              class="icon is-small"
              v-on:click="copyToClipboard('Email-' + c.Id)"
              title="Copy to clipboard"
            >
              <i class="fas fa-copy"></i>
    </span>-->
  </div>
</template>
    

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  mixins: [baseMixin],

  components: {
    contactTable: () => import("../../components/molecules/contactTable.vue")
  },
  props: {
    fulfilmentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      APIUrl: process.env.VUE_APP_APIURL,
      contactGroupDic: null
    };
  },
  created() {
    this.loadData();
    //if contactgroup was passed (or both), do nothing
  },
  methods: {
    loadData() {
      var self = this;
      self.isLoading = true;
      this.axios
        .get(
          this.APIUrl + "/fulfilment/" + this.fulfilmentId + "/contact-groups/"
        )
        .then(response => {
          this.contactGroupDic = response.data;
          self.isLoading = false;
        })
        .catch(function(re) {
          self.isLoading = false;
          self.catchError(re);
        });
    }
  }
};
</script>
